<template>
  <v-progress-circular v-if="loading" class="loader tw-m-auto" indeterminate size="48"></v-progress-circular>
  <div v-else class="individual-lead">
    <v-snackbar class="tw-mt-2 sm:tw-mt-4 alert" :timeout="3000" :value="errorMessage !== null" color="error" top>
      <span>{{ $t(`leads.protected.error.${errorMessage}`) }}</span>
    </v-snackbar>

    <v-banner :color="response.layout.primaryColor" class="individual-lead__banner">
      <div class="individual-lead__banner__background">
        <v-img
          class="individual-lead__banner__background__logo"
          contain
          :alt="response.layout.clientName"
          :src="response.layout.logo"
        />
      </div>
    </v-banner>
    <div class="individual-lead__content">
      <div class="individual-lead__content__title">
        <span class="tw-block">{{ $t('leads.public.action.title') }}</span>
      </div>
      <div class="individual-lead__content__description">
        <span class="tw-block">{{ $t('leads.public.action.description') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { setPublicLeadToConverted, setPublicLeadToLost } from '@/services/leads.service'

export default {
  name: 'PublicLeadAction',
  components: {},
  data: () => ({
    loading: false,
    response: null,
    layout: null,
    action: null,
    leadId: null,
    errorMessage: null,
  }),
  async created() {
    this.leadId = this.$route.params?.id || null
    this.action = this.$route.params?.action || null
    this.errorMessage = this.$route.query?.error || null

    this.setLocale()
    await this.loadLead()
  },
  methods: {
    setLocale() {
      const locale =
        this.$route.query?.locale ||
        navigator.language.substring(0, 2) ||
        navigator.userLanguage.substring(0, 2) ||
        this.$i18n.fallbackLocale

      this.$i18n.locale = Object.keys(this.$i18n.messages).includes(locale) ? locale : this.$i18n.fallbackLocale
      this.$vuetify.lang.current = this.$i18n.locale
    },
    async loadLead() {
      this.loading = true
      try {
        if (this.action === 'convert') {
          this.response = await setPublicLeadToConverted(this.leadId)
        }
        if (this.action === 'lose') {
          this.response = await setPublicLeadToLost(this.leadId)
        }
      } catch ({ status }) {
        return this.$router.push({ name: 'ProtectedLead404' })
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.individual-lead {
  &__banner {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__background {
      @apply tw-h-24;
      display: flex;

      &__logo {
        @apply tw-w-40 tw-mx-auto;
        flex: 0 1 auto !important;
        margin-bottom: 3.125rem;
      }
    }
  }

  &__content {
    @apply md:tw-w-1/2 md:tw-h-1/2 tw-mx-auto;
    color: #333333;
    background-color: white;
    padding-top: 2rem;
    margin-top: -3.125rem;
    z-index: 1;
    position: relative;

    &__title {
      @apply tw-font-bold tw-text-center;
    }

    &__description {
      @apply tw-text-center tw-mx-2 tw-mt-10 tw-pb-36;
    }
  }
}
</style>
